"use strict";

// NOTE TO SELF
// Found better example code to reference for improved a11y on drop menus...
// https://www.w3.org/TR/wai-aria-practices/examples/menubar/menubar-1/menubar-1.html
//
// More at
// https://www.w3.org/TR/wai-aria-practices/
// https://www.w3.org/TR/wai-aria-practices/examples/

/**
 * @typedef {('beforebegin'|'afterbegin'|'beforeend'|'afterend')} insertAdjacentHTMLPosition
 */

/**
 * Manipulates the DOM to provide a toggle for showing and hiding navigation
 *
 * @param attachButtonTo {string} - A selector string to identify which unique node to place a toggle switch into
 * @param buttonPosition {insertAdjacentHTMLPosition} - Where in relation to the node to insert the toggle <button>
 * @param navigationContainer {string} - A selector string to identify the unique node whose visibility will be toggled by this function
 */
function doMobileNavigation(
	attachButtonTo,
	buttonPosition,
	navigationContainer
) {
	const html = document.querySelector('html');
	const templateToggleNav = `
		<button id='mainNavToggle' aria-label="Main Menu">
			Menu
		</button>
	`;

	// initialise everything
		html.dataset.mobileNavigation = 'closed';

		document.querySelector(attachButtonTo)
			.insertAdjacentHTML(
				buttonPosition,
				templateToggleNav
			);

		const navToggle = document.querySelector('#mainNavToggle');

		// Accessibility enhancements for the dynamic toggling control
			const theMenu = document.querySelector(navigationContainer);
			theMenu.setAttribute("aria-labelledby", "mainNavToggle");

	// handle clicks on the menu toggle button
		navToggle.addEventListener("click",(e) => {
			let newNavStatus;
			let currentNavStatus = html.dataset.mobileNavigation;

			if (currentNavStatus == 'open') {
				// is open now, so the click is to close it
				navToggle.setAttribute("aria-expanded", false); // applies new value
				navToggle.textContent = "Menu";
				html.dataset.mobileNavigation = "closed";
			} else {
				// is closed now, so the click is to open it
				navToggle.setAttribute("aria-expanded", true); // applies new value
				navToggle.textContent = "Menu";
				html.dataset.mobileNavigation = "open";
			}
		});

	// handle keyboard focussing inside the nav
	// NOTE: this could be done in CSS with :focus-within, but then you lose ARIA status updates
	// 	document.querySelector('.site_context').addEventListener('focusin', function(e){
	// 		navToggle.setAttribute("aria-expanded", 'true');
	// 		document.querySelector('html').classList.add("nav-active");
	// 	});
	// 	document.querySelector('.site_context').addEventListener('focusout', function(e){
	// 		navToggle.setAttribute("aria-expanded", 'false');
	// 		document.querySelector('html').classList.remove("nav-active");
	// 	});
}

/**
 * Removes all the DOM changes implemented by the doMobileNavigation function
 *
 * @param navigationContainer {string} - A selector string to identify the unique node whose visibility will be toggled by this function
 */
function undoMobileNavigation(navigationContainer) {
	const html = document.querySelector('html');
	html.removeAttribute('data-mobile-navigation');

	let navMenuToggle = document.querySelector('#mainNavToggle');
	if( navMenuToggle ) {
		document.querySelector('#mainNavToggle').remove();
		document.querySelector(navigationContainer).removeAttribute('aria-labelledby');
	}
}

doMobileNavigation(
	'.site_context .right',
	'beforeend',
	'.nav_main'
);

// let screenWidth = window.matchMedia('(max-width: 680px)');
//
// // initial load
// if( screenWidth.matches ) {
// 	doMobileNavigation(
// 		'.site_context .right',
// 		'beforeend',
// 		'.nav_main');
// } else {
// 	undoMobileNavigation('.nav_main');
// }

// watch for changes in window size
// screenWidth.addEventListener("change", (e) => {
// 	if( e.matches ) {
// 		doMobileNavigation(
// 			'.site_context .right',
// 			'beforeend',
// 			'.navSite'
// 		);
// 	} else {
// 		undoMobileNavigation('.navSite');
// 	}
// });
